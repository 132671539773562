import "./hero.scss";

import React from "react";
import Wave from "../wave";
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";
import picture from "../../../static/images/articles/colab.svg";

export default (props) => {
  return (
    <>
      <section className="articles-hero grey-bg center">
        <div className="rows-center w-100">
          <div className="col-xs-12 col-sm-6">
            <div className="text-center">
              <h1>{getStaticContentByIdentifier('articles.hero.title', props.staticContent)}</h1>
              <p className="text-justify padding-l-3 padding-r-3">
              {getStaticContentByIdentifier('articles.hero.content', props.staticContent)}
                            </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <img
              src={getStaticImageByIdentifier('articles.hero.image', props.staticImages)}
              alt="Orange Bees"
              style={{ maxWidth: "622px", alignSelf: "end" }}
            />
          </div>
        </div>
      </section>
      <Wave
        className="remove-space"
        color="#ffffff"
        background="#f8f8f8"
      />
    </>
  );
};
