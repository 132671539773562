import "./articlebox.scss";

import Chip from "./chip";
import React from "react";

export default (props) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const filesBaseUrl = process.env.filesBaseUrl;

  const formatDate = (date) => {
    const d = new Date(`${date}T05:00:00Z`);
    const month = months[d.getMonth()];
    const day = d.getDate();
    const year = d.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  return (
    <section className="col- margin-3 margin-t-6">
      <div className="row bg-gradient">
        <a className="article-link padding-3" href={`${props.article.link}`} target="_blank" rel="noopener noreferrer">
          <div className="article-container">
            <div className="feature-image-container margin-l-3 margin-r-3 padding-2">
              <div className="feature-image" style={{ backgroundImage: `url('${filesBaseUrl}${props.article.featureImage.publicUrl}'` }}></div>
            </div>
            <div>
              <h3 className="text-black text-medium margin-b-1">{props.article.jobTitle} | {formatDate(props.article.date)}</h3>
              <h3 className="text-black margin-b-2">{props.article.title}</h3>
              <p className="text-medium margin-b-0 margin-t-0">{props.article.description}</p>
              {props.article.tags
                && props.article.tags.map((tag, index) => <Chip label={tag.name} key={index} />)
              }
            </div>
          </div>
        </a>
      </div>
    </section>
  )
}
