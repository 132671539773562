import Articlelist from "../components/articlelist"
import Cta from "../components/cta"
import Footer from "../components/footer"
import Header from "../components/header"
import Hero from "../components/articles/hero.js"
import Layout from "../components/layout"
import React from "react"
import Seo from "../components/seo2"
import orangeBeesLogo from '../../static/images/about/OB-logo.svg'
import { getStaticContentByIdentifier } from "../services/misc.service";
import { getSEOByIdentifier } from "../services/seo.service";

const ArticlesPage = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  const articles = props.pageContext.articles;
  const tags = props.pageContext.articleTags;
  const SEOInfo = props.pageContext.SEOInfo;


  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier('articles', SEOInfo)}/>
      <Header />
      <main id="maincontent">
        <Hero staticContent={staticContent} staticImages={staticImages}/>
        <Articlelist articles={articles} tags={tags} />
        <Cta title={getStaticContentByIdentifier('articles.CTA.content', staticContent)} button={getStaticContentByIdentifier('articles.CTA.button', staticContent)}/>
      </main>
      <Footer />
    </Layout>
  )
};

export default ArticlesPage;