import React, { useState } from 'react';

import Articlebox from "../components/articlebox"
import Chip from "../components/chip"
import Paginator from "../components/paginator"

export default (props) => {
  const articlesPreFilter = [...props.articles];
  const [filteredArticles, setFilteredArticles] = useState([...props.articles]);
  const [paginatedArticles, setPaginatedArticles] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const onPageChanged = (data) => {
    const { currentPage, recordsPerPage } = data;
    const offset = (currentPage - 1) * recordsPerPage;
    const currentArticles = filteredArticles.slice(offset, offset + recordsPerPage);

    setPaginatedArticles(currentArticles);
  };

  const handleCategoryChange = (name) => {
    let newTagSelection = [];
    const selectedTag = props.tags.find(x => x.name === name);

    if (isItemInSelection(selectedTag.name)) {
      newTagSelection = selectedTags.filter(x => x.name !== selectedTag.name);
    } else {
      newTagSelection = [...selectedTags, selectedTag];
    }

    setSelectedTags(newTagSelection);
    filterArticlesByTags(newTagSelection);
  };

  const isItemInSelection = (name) => {
    return selectedTags.some(x => x.name === name);
  }

  const filterArticlesByTags = (newTagSelection) => {
    let filteredArticles = [...articlesPreFilter];
    const tags = newTagSelection.map(x => x.name);

    if (tags.length > 0) {
      filteredArticles = filteredArticles.filter(article => {
        const articleTags = article.tags;
        const matchedTags = articleTags.filter(({ name }) => tags.some(x => name === x));
        return matchedTags.length === tags.length;
      });
    }

    setFilteredArticles(filteredArticles);
  }

  return (
    <div className="padding-b-6">
      {props.tags.length >= 1
        && (
          <div className="margin-l-3 margin-r-3 margin-b-3 margin-t-0">
            <h3 className="row text-medium">Categories</h3>
            <div className="category-container row">
              {props.tags.map((tag, index) =>
                <Chip onChipClicked={handleCategoryChange}
                  isClickable={true}
                  label={tag.name}
                  active={isItemInSelection(tag.name)}
                  key={index} />
              )}
            </div>
          </div>
        )
      }
      {filteredArticles.length >= 1
        ? (
          <>
            <ul>
              {paginatedArticles.map((article, index) => (
                <li key={index}>
                  <Articlebox article={article} />
                </li>
              ))}
            </ul>
            <Paginator totalRecords={filteredArticles.length}
              onPageChanged={onPageChanged} />
          </>
        )
        : (
          <p className="text-center margin-t-6">
            No articles found for the selected categories.
          </p>
        )
      }

    </div>
  )
}