import React, { useEffect, useState } from 'react';
import './paginator.scss';

function Paginator(props) {
    const { totalRecords = null, recordsPerPage = 5, bound = 4 } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    useEffect(() => { 
        goToPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalRecords]);

    const getPageNumbers = () => {
        const currentSet = (currentPage % bound === 0) ? parseInt(currentPage / bound) 
                                                       : parseInt(currentPage / bound) + 1;
        const end = Math.min(totalPages, bound * currentSet);
        const start = Math.max(1, end - (bound - 1));

        return range(start, end);
    };

    const goToPage = (pageNumber) => {
        const { onPageChanged = func => func } = props;
        const currentPage = Math.max(1, Math.min(pageNumber, totalPages));
        
        setCurrentPage(currentPage);
        onPageChanged({ currentPage, recordsPerPage });
    };

    const handlePrevious = () => {
        goToPage(currentPage - 1);
    }

    const handleNext = () => {
        goToPage(currentPage + 1);
    }

    return (
        <div className="paginator">
            <p className="current-page-text text-xs">Page {currentPage} of {totalPages}</p>
            <div className="paginator-controls">
                <button className="no-style-button paginator-button text-xs" 
                        title="Previous Page"
                        disabled={currentPage === 1}
                        onClick={() => handlePrevious()}>
                    Previous
                </button>
                <ul className="paginator-page-list">
                    {getPageNumbers().map((page, index) => (
                        <li key={index}>
                            <button
                                title={'Page ' + page}
                                onClick={() =>  goToPage(page)} 
                                className={`page no-style-button text-xs ${page === currentPage ? 'active-page' : ''}`}>
                                {page}
                            </button>
                        </li>)
                    )}
                </ul>
                <button className="no-style-button paginator-button text-xs"
                        title="Next Page" 
                        disabled={currentPage === totalPages}
                        onClick={() => handleNext()}>
                    Next
                </button>
            </div>
        </div>
    );
}

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

export default Paginator;