import "./chip.scss"

import React from "react"

export default (props) => {
  const { label, count = null, active = false, isClickable = false, onChipClicked } = props;
  const handleClick = () => onChipClicked(label);

  return (
    isClickable
      ? (
        <button className={`no-style-button chip ${active ? 'active' : ''}`} onClick={handleClick}>
          <span className="chip-label text-medium">{label}</span>
          {count && (<span className="chip-count text-medium">{count}</span>)}
        </button>
      )
      : (
        <div className={`chip ${active ? 'active' : ''}`}>
          <span className="chip-label text-medium">{label}</span>
          {count && (<span className="chip-count text-medium">{count}</span>)}
        </div>
      )
  );
}